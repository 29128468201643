
















import { Component, Prop, Vue } from "vue-property-decorator";
import BreedDetail from "@/components/BreedDetail.vue";
import Chip from "@/components/Chip.vue";
import "@/assets/style.scss";
import { Mutation } from "vuex-class";

@Component({
  components: {
    BreedDetail,
    Chip,
  },
})
export default class DogCard extends Vue {
  @Mutation("addCurrentBreed") addCurrentBreed!: any;

  @Prop({ required: true, type: String }) name!: string;
  @Prop({ required: false, default: "" }) bredFor!: string;
  @Prop({ required: false }) height!: string;
  @Prop({ required: true }) weight!: string;
  @Prop({ required: true }) id!: number;
  @Prop({ required: true }) lifeSpan!: string;
  @Prop({ required: false }) traits!: Array<string>;
  @Prop({ required: true }) pic!: string;
  @Prop({ required: true }) bg!: string;
  @Prop({ required: false }) description!: string;
  @Prop({ required: false }) wiki!: string;
  @Prop({ required: false }) origin!: string;

  /**
   * truncate Truncate dog's name if it is too long
   */
  public truncate(text: string, length: number, suffix: string): string {
    if (text.length > length) {
      return text.substring(0, length) + suffix;
    } else {
      return text;
    }
  }

  /**
   * seeDetail Go to detail page of the clicked dog
   */
  public seeDetail() {
    this.addCurrentBreed({
      name: this.name,
      bredFor: this.bredFor,
      height: this.height,
      weight: this.weight,
      id: this.id,
      lifeSpan: this.lifeSpan,
      traits: this.traits,
      origin: this.origin,
      wiki: this.wiki,
      description: this.description,
      pic: this.pic,
      bg: this.bg,
    });

    // Scroll to top
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;

    this.$emit("clickedDog");
  }
}








import { Component, Prop, Vue } from "vue-property-decorator";
import "@/assets/style.scss";

@Component
export default class Chip extends Vue {
  @Prop({ required: true, type: String }) name!: string;
  @Prop({ required: false, type: String, default: "chip-md" }) size!: string;
}























































































import { Component, Prop, Vue } from "vue-property-decorator";
import Chip from "@/components/Chip.vue";
import "@/assets/style.scss";
import { Getter } from "vuex-class";
import { Breed } from "@/store/types";

@Component({
  components: {
    Chip,
  },
})
export default class DogDetail extends Vue {
  @Getter("currentBreed") currentBreed!: Breed;
  @Getter("mobile") mobile!: string;
  @Getter("isDog") isDog!: string;

  @Prop({ required: false, type: Boolean }) loaded!: boolean;
}

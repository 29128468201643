






































































import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";

export default Vue.extend({
  name: "App",
  computed: {
    ...mapGetters(["mobile", "isDog"]),
  },
  data: () => ({
    icons: ["mdi-home", "mdi-email", "mdi-calendar", "mdi-delete"],
    tab: null,
    items: ["Dog", "Cat"],
  }),
  methods: {
    ...mapMutations(["updateIsDog", "updateToDog", "updateToCat"]),
    changeIsDog() {
      this.updateIsDog();
      if (this.$route.name != "home") this.$router.push("/");
    },
    changeToDog() {
      if (!this.isDog) {
        if (this.$route.name != "home") this.$router.push("/");
      }
      this.updateToDog();
    },
    changeToCat() {
      if (this.isDog) {
        if (this.$route.name != "home") this.$router.push("/");
      }
      this.updateToCat();
    },
  },
});
